.mdxeditor-root-contenteditable {
  height: 350px;
  border: 8px solid #ebeced;
  background-color: #ffffff;
  overflow-y: scroll;
}
.mdxeditor-toolbar {
  border-radius: 8px 8px 0 0;
  padding: 16px 16px;
  gap: 12px;
}