.thumb-btn {
  outline: none;
  background-color: white;
  border: none;
  display: flex;
  flex-direction: row;
}
.thumb-img {
  width: 50px;
  height: 50px;
}
.thumb-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
}

.thumb-callout {
  
}
.thumb-preview {
  
}
