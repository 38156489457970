.team-member-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
}

.team-member {
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-size: 14px;
  text-align: center;
  background: white;
  outline: none;
  border-radius: 8px;
}

.team-member:hover {
  background: #f0f0f0;
}

.team-member.selected {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.loading {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}
.team-member-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
}

.team-member {
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-size: 14px;
  text-align: center;
  background: white;
  outline: none;
  border-radius: 8px;
}

.team-member:hover {
  background: #f0f0f0;
}

.team-member.selected {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.loading {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}
