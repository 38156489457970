/* Customize toolbar background */
.ql-toolbar {
  background-color: #3b82f6;
  border-radius: 8px 8px 0 0;
}

/* Customize icon colors in the toolbar */
.ql-snow .ql-stroke {
  stroke: white; 
}
.ql-toolbar button {
  padding: 8px;
}
/* Customize the toolbar buttons (hover and active states) */
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar button:hover {
  background-color: #e0e0e0;
}

.ql-toolbar button.ql-active {
  background-color: #93c5fd;
}

/* Customize the editor text area padding and background */
.ql-editor {
  background-color: #f9f9f9;
  padding: 15px; 
  min-height: 300px; 
}

/* Customize scrollbar inside the editor */
.ql-editor::-webkit-scrollbar {
  width: 8px;
}

.ql-editor::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#toolbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

#toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
}

#toolbar button:hover {
  background-color: #f0f0f0;
}

.ql-size {
  margin-right: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}